<script setup lang='ts'>
import { useAvatar } from '@Composables/avatar';

defineProps({
  text: {
    required: true,
      type: String
  }
})

const { createShortcut, getIndex } = useAvatar();

function setLogo(name: string): string {
  return createShortcut(name);
}

function setIndex(name: string): number {
  return getIndex(name);
}
</script>

<template>
  <div
    class="logo__icon vertical-align-center"
    :class="`icon-${setIndex(text)}`"
  >
    {{ setLogo(text) }}
  </div>
</template>

<style scoped lang="scss">
@import "@Assets/scss/avatar-component.scss";
</style>
