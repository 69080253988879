export const useAvatar = () => {
  const createShortcut = (text: string): string => {
    let letters = text.split(' ');

    let shortcut = '';

    letters = letters.filter((el) => {
      return el;
    });

    letters.forEach((element) => {
      // max 2 znaki
      if (shortcut.length >= 2) return;

      shortcut += element[0];
    });

    return shortcut;
  };

  const getIndex = (text: string): number => {
    const alphabet = [
      ['a', 'ą', 'b'],
      ['c', 'ć', 'd'],
      ['e', 'ę', 'f'],
      ['g', 'h', 'i'],
      ['j', 'k', 'l', 'ł'],
      ['m', 'n', 'ń', 'o'],
      ['ó', 'p', 'q', 'r'],
      ['s', 'ś', 't', 'u'],
      ['v', 'w', 'x', 'y'],
      ['z', 'ź', 'ż']
    ];

    const letters = text.match(/\p{Lu}/gu);
    let index = 0;

    if (letters != null) {
      letters.forEach((letter) => {
        alphabet.find((array, position) => {
          if (array.indexOf(letter.toLowerCase()) >= 0) {
            index += position;
          }
        });
      });
    }
    if (index >= 9) return index % 9;
    return index;
  };

  return {
    createShortcut,
    getIndex
  };
};
